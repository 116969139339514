import { giphyIndigo } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import styled from 'styled-components'

export const labelHeight = 28
const Label = styled.div`
    display: block;
    font-size: 14px;
    text-align: left;
    height: 28px;
    line-height: ${labelHeight}px;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 10px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${giphyIndigo};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    span {
        position: relative;
    }
`

type Props = {
    gif: IGif
    className?: string
    label?: string
}
const LabelOverlay = ({ gif, className, label }: Props) => {
    return (
        <>
            {
                gif.is_hidden && null
                // <LockContainer>
                //     <Lock />
                // </LockContainer>
            }
            <Label className={`${LabelOverlay.className} ${className}`}>
                <span>{label || gif.title}</span>
            </Label>
        </>
    )
}
LabelOverlay.className = 'giphy-gif-label-overlay'

export default LabelOverlay
