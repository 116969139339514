import { convertToImagingService } from '@/util/asset-url'
import { IChannel } from '@giphy/js-types'
import Link from 'next/link'

type Props = { channel: IChannel }
const Banner = ({ channel }: Props) => {
    const { ancestors = [] } = channel
    const channelWithBanner = [...ancestors, channel].reverse().find((c) => c.banner_image)
    if (!channelWithBanner?.banner_image) {
        return null
    }
    const url = convertToImagingService(channelWithBanner.banner_image)
    return (
        <Link href={`/${channel.slug}`} aria-label={`${channel.display_name}`}>
            <img
                src={url}
                height={160}
                width={1040}
                alt={channel.display_name}
                className="my-1.5 h-full max-h-40 w-full object-cover"
            />
        </Link>
    )
}

export default Banner
