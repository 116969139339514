import PaginationCarousel from '@/components/pagination-carousel'
import { SectionHeader } from '@/components/sections'
import UAParserContext from '@/context/ua-parser'
import { IUser } from '@giphy/js-types'
import { useContext, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { IStory } from 'types'
import useFetchWithCursor from 'ui/src/hooks/use-fetch-with-cursor'
import useClientRender from '../../util/use-client-render'
import StoryLink from '../stories/link'

const Container = styled.div`
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    gap: 4px;
`

type Props = {
    initialUrl: string
    user: IUser
    initialData?: IStory[]
    onClick: (story: IStory) => void
}

const StoriesCarousel = ({ initialUrl, initialData = [], onClick, user }: Props) => {
    const { deviceType } = useContext(UAParserContext)
    const node = useRef<HTMLDivElement | null>(null)
    const gifHeight = deviceType === 'desktop' ? 212 : 186
    const gifWidth = deviceType === 'desktop' ? 182 : 160
    const { ref, inView } = useInView({
        rootMargin: `${gifWidth}px`,
        root: node.current,
    })
    const canRender = useClientRender()
    const { doneFetching, gifs, doFetch } = useFetchWithCursor<IStory>({
        initialData,
        initialUrl,
        triggerFetch: inView && !!initialUrl,
    })
    const getStory = (story: IStory) => {
        return (
            <div style={{ width: gifWidth }} key={story.story_id}>
                <StoryLink story={story} height={gifHeight} width={gifWidth} onClick={onClick} />
            </div>
        )
    }
    return deviceType === 'desktop' ? (
        <PaginationCarousel
            className={'w-detailContentWidth mb-5'}
            onDone={doFetch}
            title={<SectionHeader link={`/${user.username}/stories`} label={'Stories'} isHref />}
            maxBlocks={4}
            itemHeight={gifHeight}
        >
            {gifs.map(getStory)}
        </PaginationCarousel>
    ) : (
        <>
            <SectionHeader label="Stories" />
            <Container ref={node}>
                {gifs.map(getStory)}
                {!doneFetching && canRender && (
                    <div ref={ref} className="inline-block" style={{ height: gifHeight, width: 100 }} />
                )}
            </Container>
        </>
    )
}

export default StoriesCarousel
