'use client'
import PaginationCarousel from '@/components/pagination-carousel'
import UAParserContext from '@/context/ua-parser'
import { cn } from '@/util/cn'
import { giphyRed } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { Gif, GifOverlayProps, Grid as SDKGrid, VideoOverlay } from '@giphy/react-components'
import Link from 'next/link'
import { useContext, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import useFetchData from 'ui/src/hooks/use-fetch-data'
import { PlayIcon as PlayIcon_ } from 'ui/src/icons/player'
import { LockIcon } from 'ui/src/icons/utility'
import { relativeUrl } from '../../util/url'
import { SectionHeader } from '../sections'
import Username_ from '../username'
import { CarouselContainer } from './sc'

const Username = styled(Username_)`
    font-size: 12px;
`
const Title = styled.div`
    font-size: 17px;
    font-weight: bold;
    line-height: 1.5;
    overflow: hidden;
    margin: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`
const Details = styled.div`
    padding: 4px;
    display: flex;
    flex-direction: column;
`

const MobileOverlay = styled.div<{ isHidden?: boolean }>`
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) =>
        props.isHidden &&
        css`
            background: rgba(0, 0, 0, 0.5);
        `}
    pointer-events: none;
    transition: opacity 0.2s ease-out 0s;
`
const PlayIcon = styled(PlayIcon_)`
    position: absolute;
    bottom: 10px;
    left: 10px;
`
const Overlay = ({ gif }: GifOverlayProps) => {
    return (
        <MobileOverlay isHidden={gif.is_hidden}>
            {gif.is_hidden && <LockIcon height={20} color={giphyRed} />}
            <PlayIcon width={22} />
        </MobileOverlay>
    )
}

type Props = Pick<SDKGrid['props'], 'initialGifs' | 'fetchGifs'> & { hideUsername?: boolean; link: string }

/**
 * A fixed size grid with titles
 */
const VideoCarousel = ({ initialGifs = [], fetchGifs, hideUsername, link }: Props) => {
    const { deviceType } = useContext(UAParserContext)
    const isDesktop = deviceType === 'desktop'
    const node = useRef<HTMLDivElement | null>(null)
    const gifHeight = isDesktop ? 140 : 90
    const gifWidth = isDesktop ? 248 : 160
    // fetch gifs in advance of gif width px so you don't see the loader if you slow scroll
    const { ref, inView } = useInView({
        rootMargin: `${gifWidth / 2}px`,
        root: node.current,
    })
    const { doneFetching, gifs, doFetch } = useFetchData<IGif>({
        initialData: initialGifs,
        fetchData: fetchGifs,
        triggerFetch: inView,
    })
    const getItem = (gif: IGif) => {
        return (
            <Link
                href={relativeUrl(gif.url)}
                key={gif.id}
                className={cn('inline-flex flex-col pb-2.5', isDesktop ? 140 : gifWidth)}
            >
                <Gif
                    noLink
                    gif={gif}
                    className="[&_img]:object-cover"
                    hideAttribution
                    width={gifWidth}
                    height={gifHeight}
                    overlay={
                        isDesktop
                            ? (props) => (
                                  <VideoOverlay
                                      className="[&_video]:object-cover"
                                      {...props}
                                      width={gifWidth}
                                      height={gifHeight}
                                  />
                              )
                            : Overlay
                    }
                />
                {!isDesktop && (
                    <Details>
                        <Title>{gif.title}</Title>
                        {!hideUsername && gif.user && (
                            <Username user={gif.user} alternateName={gif.user.display_name} />
                        )}
                    </Details>
                )}
            </Link>
        )
    }
    return isDesktop ? (
        <PaginationCarousel
            maxBlocks={3}
            onDone={doFetch}
            gap={16}
            title={<SectionHeader link={link} label="Clips" />}
            itemHeight={gifHeight}
        >
            {gifs.map(getItem)}
        </PaginationCarousel>
    ) : (
        <CarouselContainer ref={node}>
            <SectionHeader link={link} label="Clips" tag="h5" />
            {gifs.map(getItem)}
            {!doneFetching && <div ref={ref} className="inline-block" style={{ height: gifHeight, width: 100 }} />}
        </CarouselContainer>
    )
}

export default VideoCarousel
