import { useCallback, useEffect, useRef, useState } from 'react'
import useDebounce from 'react-use/lib/useDebounce'
import { AnotherCursorResult, CursorResult } from 'types'

/**
 * a completely different api response for stories, and it doesn't take offset :/
 * leave the generic type there so we can port it to useFetch<T> when it supports
 * these things
 */
export const useFetchWithCursor = <T>({
    initialData = [],
    triggerFetch = false,
    initialUrl,
    origin,
}: {
    initialData?: T[]
    initialUrl: string
    triggerFetch?: boolean
    origin?: string
}) => {
    // put everything in a ref so doFetch can retain referential equality
    const gifs = useRef<T[]>(initialData)
    const [, updateState] = useState<any>()
    const nextPage = useRef<string>(initialUrl)
    const forceUpdate = useCallback(() => updateState({}), [])
    const doneFetching = useRef<boolean>(false)
    const isFetching = useRef(false)
    const doFetch = useCallback(async () => {
        if (doneFetching.current || isFetching.current) {
            return
        }
        isFetching.current = true
        const result = await fetch(nextPage.current)
        const json = (await result.json()) as unknown
        const { data, pagination } = (json || {}) as CursorResult<T>
        let { next, results } = (json || {}) as AnotherCursorResult<T>
        // get next url from pagination or next prop
        let nextUrl = pagination?.next_page || next || ''
        // optionally override the origin to help test
        if (origin && nextUrl) {
            const p = new URL(nextUrl)
            nextUrl = `${origin}/${p.pathname}?${p.searchParams}`
        }
        nextPage.current = nextUrl
        // get the data from data or results
        const items = data || results || []
        const allGifs = [...gifs.current, ...items]
        doneFetching.current = !nextPage.current
        gifs.current = [...allGifs]
        isFetching.current = false
        // updating refs won't trigger a render
        forceUpdate()
    }, [])
    // check if we're still in view after fetching some content
    useDebounce(
        () => {
            if (triggerFetch) doFetch()
        },
        100,
        [gifs.current.length]
    )
    // optional triggerFetch property will trigger a fetch
    useEffect(() => {
        if (triggerFetch) {
            doFetch()
        }
    }, [triggerFetch, doFetch])
    return { doneFetching: doneFetching.current, doFetch, gifs: gifs.current }
}

export default useFetchWithCursor
