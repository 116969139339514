'use client'
import { getChannelChildren, getChannelClipsByUsername, getChannelGifsById } from '@/app/util/channel-api'
import ChannelCarousel from '@/components/carousel/channel-carousel'
import StoriesCarousel from '@/components/carousel/stories-carousel'
import VideoCarousel from '@/components/carousel/video-carousel'
import Grid from '@/components/grid/grid'
import { SectionHeader } from '@/components/sections'
import Username from '@/components/username'
import UAParserContext from '@/context/ua-parser'
import { NexaBlack } from '@/styles/fonts'
import { cn } from '@/util/cn'
import { IChannel, IGif } from '@giphy/js-types'
import { useCallback, useContext, useEffect, useState } from 'react'
import { IStory, StoryResult } from 'types'
import { UserAnalytics } from './analytics-api'
import Banner from './components/banner'
import ChannelInfo from './components/channel-info'
import { containerCss, desktopContainerCss } from './constants'

// fetch the first few gifs on the server
const clientsideFetchLimit = 25

type Props = {
    channel: IChannel
    initialGifs?: IGif[]
    initialClips?: IGif[]
    analytics?: UserAnalytics
    storyData?: StoryResult
}

const ChannelPageContents = ({ channel: fetchedChannel, analytics, initialGifs, initialClips, storyData }: Props) => {
    const [channel, setChannel] = useState<IChannel>(fetchedChannel)
    const { deviceType } = useContext(UAParserContext)
    const {
        id,
        user: { username, display_name },
    } = channel

    const fetchClips = useCallback(
        async (offset: number) => {
            return await getChannelClipsByUsername(
                username,
                { offset, limit: clientsideFetchLimit },
                document.cookie as string
            )
        },
        [username]
    )
    const fetchGifs = useCallback(
        async (offset: number) => {
            return await getChannelGifsById(id, { offset, limit: clientsideFetchLimit }, document.cookie)
        },
        [id]
    )

    useEffect(() => {
        // fetch the rest of the children that we didn't get on the server
        const fetchChildren = async () => {
            if (channel.children.length < channel.num_children) {
                const children = await getChannelChildren(id, document.cookie as string, {
                    fetch_associated_channels: true,
                    offset: channel.children.length,
                })
                setChannel({ ...channel, children: [...channel.children, ...children.data] })
            }
        }
        fetchChildren()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <Banner channel={channel} />
            <div className={cn(containerCss, desktopContainerCss)}>
                <ChannelInfo channel={channel} analytics={analytics} display_name={display_name} />
                <div className={cn(containerCss)}>
                    <section className="hidden flex-col md:flex">
                        <h1 className={`${NexaBlack.className} text-3xl`}>{channel.user.display_name}</h1>
                        <Username user={channel.user} className="[&_svg]:fill-giphyBlue text-giphyLightGrey" />
                    </section>
                    {storyData?.data && storyData?.data.length > 0 && (
                        <section>
                            <StoriesCarousel
                                user={channel.user}
                                initialData={storyData.data}
                                initialUrl={storyData.pagination.next_page}
                                onClick={(story: IStory) => {
                                    location.href = `/stories/${story.slug}`
                                }}
                            />
                        </section>
                    )}
                    {channel.has_clips_feed && (
                        <section>
                            <VideoCarousel
                                link={`/${username}/clips`}
                                initialGifs={initialClips}
                                fetchGifs={fetchClips}
                                hideUsername
                            />
                        </section>
                    )}
                    {channel.children?.map((child, index) => {
                        return <ChannelCarousel channel={child} key={child.id} index={index} />
                    })}
                    <section>
                        <SectionHeader label={`All the ${channel.user.display_name} GIFs`} />
                        <Grid
                            columns={deviceType === 'desktop' ? 3 : undefined}
                            initialGifs={initialGifs}
                            fetchGifs={fetchGifs}
                            key={`all-gifs-${channel.id}`}
                            width={deviceType === 'desktop' ? 776 : undefined}
                        />
                    </section>
                </div>
            </div>
        </>
    )
}

export default ChannelPageContents
