import { channelsUrl } from '@/app/util/channel-api'
import { giphyLocalUrl, giphyUrl } from '@/app/util/env'
import { CarouselContainer as _CarouselContainer } from '@/components/carousel/sc'
import { appendParams } from '@/util/url'
import useClientRender from '@/util/use-client-render'
import { giphyAqua, giphyIndigo, giphyLightBlue, giphyPink } from '@giphy/colors'
import { IChannel } from '@giphy/js-types'
import { Gif } from '@giphy/react-components'
import Link from 'next/link'
import { useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import useFetchWithCursor from 'ui/src/hooks/use-fetch-with-cursor'
import _LabelOverlay from './gif-label-overlay'
export const gifHeight = 110
export const gifWidth = 160
const marginBottom = 4
const generateLabelOpacityCss = () => {
    let result = ''
    for (let i = 0; i <= 17; i++) {
        result +=
            `&:nth-child(18n-${i}) .${_LabelOverlay.className}::before{` +
            `opacity: ${i > 8 ? (i - 17) * -0.1 : i * 0.1}` +
            `}\n`
    }
    return result
}

const CarouselContainer = styled(_CarouselContainer)`
    display: flex;
    gap: 6px;
    min-height: ${gifHeight}px;
    margin-bottom: ${marginBottom}px;
    padding-bottom: 10px;
    .${Gif.className} {
        ${generateLabelOpacityCss()}
    }
`

const Item = styled.div`
    display: inline-flex;
    flex-direction: column;
    width: ${gifWidth}px;
`

type Props = { initialChannels?: IChannel[]; channel: IChannel; index: number }

const LabelOverlay = styled(_LabelOverlay)<{ backgroundColor: string }>`
    ${(props) =>
        props.backgroundColor &&
        css`
            background-color: ${props.backgroundColor};
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: black;
            }
        `}
`

const Loader = styled.div`
    height: 100px;
    width: ${gifWidth}px;
    display: inline-block;
`

const colors = [giphyAqua, giphyPink, giphyIndigo, giphyLightBlue]

// we want to fetch 3 on page load, and more as the user scrolls
// but we can't do that with a cursor endpoint (as opposed to an offset endpoint)
const numberOfChildrenToFetch = 3
const ChannelFeedCarousel = ({ channel, index }: Props) => {
    const { children = [] } = channel
    // make sure the container is on the screen
    const { ref: container, inView: containerInView } = useInView({})
    // for infinite scroll
    const carouselRef = useRef<HTMLDivElement | null>(null)
    const { ref, inView } = useInView({
        rootMargin: `${gifWidth / 3}px`,
        root: carouselRef.current,
    })

    const canRender = useClientRender()

    // make sure both the container and the loader is visible before fetching
    const needsKids = containerInView && inView && channel.num_children > children.length

    const initialUrl = appendParams(new URL(`${giphyLocalUrl}${channelsUrl}${channel.id}/children`), {
        fetch_associated_channels: false,
        limit: numberOfChildrenToFetch,
    }).toString()
    const { gifs: kids, doneFetching } = useFetchWithCursor<IChannel>({
        initialData: channel.children,
        initialUrl,
        origin: giphyUrl,
        triggerFetch: needsKids && !!initialUrl,
    })
    const makeGif = (channel: IChannel) => {
        return channel.featured_gif ? (
            <Item key={channel.id}>
                <Link href={channel.url}>
                    <Gif
                        backgroundColor="transparent"
                        borderRadius={0}
                        gif={channel.featured_gif}
                        height={gifHeight}
                        width={gifWidth}
                        noLink
                        hideAttribution
                        overlay={({ gif }) => (
                            <LabelOverlay
                                gif={gif}
                                label={channel.short_display_name || channel.display_name}
                                backgroundColor={colors[index % colors.length]}
                            />
                        )}
                    />
                </Link>
            </Item>
        ) : (
            <Item key={channel?.id}>
                <Link href={channel.url}>
                    {/* not sure what to show if there's no featured gif */}
                    {/*eslint-disable-next-line @next/next/no-img-element */}
                    <img
                        alt={channel.description}
                        style={{ width: gifWidth, height: gifHeight, objectFit: 'cover' }}
                        src={channel.user.avatar_url}
                    />
                </Link>
            </Item>
        )
    }
    return (
        <div ref={container}>
            <CarouselContainer ref={carouselRef}>
                {kids.map(makeGif)}
                {!doneFetching && canRender && <Loader ref={ref} />}
            </CarouselContainer>
        </div>
    )
}

export default ChannelFeedCarousel
