'use client'
import ChannelFeedCarousel from '@/app/(site)/[username]/components/channel-feed-carousel'
import { getChannelGifsById } from '@/app/util/channel-api'
import PaginationCarousel from '@/components/pagination-carousel'
import UAParserContext from '@/context/ua-parser'
import { cn } from '@/util/cn'
import { IChannel, IGif } from '@giphy/js-types'
import { Carousel, Gif } from '@giphy/react-components'
import { useContext } from 'react'
import { useInView } from 'react-intersection-observer'
import useFetchData from 'ui/src/hooks/use-fetch-data'
import { SectionHeader } from '../sections'
import { CarouselContainer } from './sc'

type Props = Pick<Carousel['props'], 'initialGifs'> & {
    hideUsername?: boolean
    index?: number
    channel: IChannel
}
const clientsideFetchLimit = 25

const PaginationChannelCarousel = ({ channel, initialGifs = [] }: Props) => {
    const title = channel.short_display_name || channel.display_name
    const { ref, inView } = useInView()

    const fetchGifs = async (offset: number) =>
        getChannelGifsById(channel.id, {
            offset,
            limit: clientsideFetchLimit,
        })

    const { gifs, doFetch } = useFetchData<IGif>({
        initialData: initialGifs,
        fetchData: fetchGifs,
    })
    return (
        <PaginationCarousel
            onDone={inView ? doFetch : undefined}
            gap={16}
            title={
                <div className="flex">
                    <SectionHeader link={channel.url} label={title} />
                    <span ref={ref}></span>
                </div>
            }
            maxBlocks={3}
            itemHeight={140}
        >
            {gifs.map((gif: IGif) => {
                return (
                    <Gif
                        key={gif.id}
                        gif={gif}
                        height={140}
                        className={cn('[&_img]:object-cover', gif.is_sticker && '[&_img]:object-contain')}
                        width={248}
                        hideAttribution
                    />
                )
            })}
        </PaginationCarousel>
    )
}

const ChannelCarousel = ({ channel, initialGifs = [], index }: Props) => {
    const gifHeight = 110
    const gifWidth = 160
    const { deviceType } = useContext(UAParserContext)
    const title = channel.short_display_name || channel.display_name
    const fetchGifs = async (offset: number) =>
        getChannelGifsById(channel.id, {
            offset,
            limit: clientsideFetchLimit,
        })
    return deviceType === 'desktop' ? (
        <PaginationChannelCarousel channel={channel} />
    ) : (
        <div key={channel.id}>
            <SectionHeader link={channel.url} label={title} tag="h5" />
            {channel.has_children ? (
                <ChannelFeedCarousel channel={channel} index={index!} />
            ) : (
                <CarouselContainer>
                    <Carousel
                        backgroundColor={`rgba(0,0,0,0)`}
                        borderRadius={0}
                        initialGifs={initialGifs}
                        hideAttribution
                        fetchGifs={fetchGifs}
                        gifWidth={gifWidth}
                        gifHeight={gifHeight}
                    />
                </CarouselContainer>
            )}
        </div>
    )
}

export default ChannelCarousel
