import { giphyWhiteSmoke } from '@giphy/colors'
import { Gif } from '@giphy/react-components'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { IStory } from 'types'
import _Avatar from '../avatar'

const _Date = dynamic(() => import('./date'))

const padding = 6

const Cascade = styled.div``
const Cascade1 = styled(Cascade)`
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.35);
    height: 3px;
    opacity: 0.8;
`
const Cascade2 = styled(Cascade)`
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.35);
    height: 6px;
    margin: 0 6px;
    opacity: 0.6;
`
const Cascade3 = styled(Cascade)`
    height: 6px;
    margin: 0 12px;
    opacity: 0.4;
`

const Item = styled.div<{ cascade: string }>`
    margin-bottom: ${padding}px;
    position: relative;
    .${Gif.imgClassName} {
        object-fit: cover;
    }
    ${Cascade} {
        background: ${(props) => props.cascade};
    }
`

const Content = styled.div`
    position: relative;
`

const Date = styled(_Date)`
    position: relative;
    color: ${giphyWhiteSmoke};
    font-size: 13px;
    font-weight: bold;
`

const Title = styled.div`
    position: relative;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.2;
    user-select: none;
`

const Overlay = styled.div`
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Footer = styled.div`
    position: relative;
    &:before {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(18, 18, 18, 0.6));
        content: '';
        top: -40px;
        left: 0;
        bottom: 0;
        right: 0;
        margin: -${padding}px;
        position: absolute;
        transition: opacity 150ms linear;
    }
`
const FooterInner = styled.div`
    padding: 10px;
`

const Avatar = styled(_Avatar)`
    margin: 10px;
`

type Props = {
    story: IStory
    width: number
    height: number
    onClick: (story: IStory) => void
}

const StoryLink = ({ story, width, height, onClick }: Props) => {
    const color = `#${story.color}`
    return (
        <Item cascade={color}>
            <Content>
                <Gif
                    gif={story.cover_gif?.gif!}
                    backgroundColor={color}
                    width={width}
                    height={height}
                    hideAttribution
                    onGifClick={(gif, event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        onClick(story)
                    }}
                />
                <Overlay>
                    {story.user && <Avatar user={story.user} size={36} />}
                    <Footer>
                        <FooterInner>
                            <Title>{story.title}</Title>
                            <Date time={story.publish_datetime} />
                        </FooterInner>
                    </Footer>
                </Overlay>
            </Content>
            <>
                <Cascade1 />
                <Cascade2 />
                <Cascade3 />
            </>
        </Item>
    )
}

export default StoryLink
